/** @jsxImportSource theme-ui */
import { colors } from '@material-ui/core'
import { GetStaticPaths, GetStaticProps } from 'next'
import dynamic from 'next/dynamic'
import { memo, useEffect, useState } from 'react'
import SEO from 'src/components/SEO'
import { Box, Container } from 'theme-ui'
import { pageHeaderProps } from '~/api/header-settings'
import { queryContentful, withPageProps } from '~/api/services'
import { getStampedAllQuestions, getStampedReviewsByProductId__client } from '~/api/stamped'
import { Styled } from '~/components/styled'
import ContentfulSections from '~/features/contentful-sections'
import { ProductReviews } from '~/features/product/Product-Reviews'
import { getAllActiveShopifyProductPages } from './products/[handle]'
import { useTheme } from '~/hooks/use-theme'

type NotRedirect<T> = T extends { notFound: true } ? never : T;
type Props = NotRedirect<Awaited<ReturnType<typeof getStaticProps>>>['props'];

const AgeVerificationPopup = dynamic(
  () => import('~/components/Age-Verification-Popup'),
  { ssr: false }
);

function Page({ contentful, stampedReviewSummary, allStampedReview, reviewsWithMedia, allStampedQuestion, product, handle }: Props) {
  const [showAgePopup, setShowAgePopup] = useState(false);

  useEffect(() => {
    const isHomepage = contentful?.handle === 'home';
    const ageVerified = localStorage.getItem('ageVerified');

    if (isHomepage && (!ageVerified || ageVerified === 'false')) {
      setShowAgePopup(true);
    }
  }, [contentful]);

  const handleAgeVerified = () => {
    setShowAgePopup(false);
    localStorage.setItem('ageVerified', 'true');
  };

  const { colors } = useTheme()

  let seoImage = contentful?.seoImage?.url;
  seoImage =
    seoImage?.startsWith('http:') || seoImage?.startsWith('https:')
      ? seoImage
      : `https:${seoImage}`;

  return (
    <Box
      sx={{
        backgroundColor: contentful?.backgroundColor?.colorArray,
        minHeight: contentful?.backgroundColor ? '100vh' : undefined,
      }}
    >
      {showAgePopup && <AgeVerificationPopup onVerified={handleAgeVerified} />}

      <SEO
        title={contentful?.metaTitle ?? contentful?.title}
        description={contentful?.metaDescription}
        image={seoImage}
        type="website"
        key={`SEO-${contentful?.title}`}
      />
      <main>
        <ContentfulSections sections={contentful?.sections} />
        {contentful?.includeProductsReviews && (
          <Box
            sx={{
              backgroundImage: `linear-gradient(${colors.background}, ${colors.secondary})`,
              py: [5, 6],
            }}
          >
            <Container>
              <Styled.h2 sx={{ textAlign: 'center', fontSize: 6 }}>
                Product Reviews
              </Styled.h2>
            </Container>
            <ProductReviews
              summary={stampedReviewSummary}
              questions={allStampedQuestion ?? undefined}
              reviews={allStampedReview?.data}
              productTitle={product?.title ?? contentful?.title ?? ''}
              reviewsWithImages={reviewsWithMedia?.data}
              productId={product?.id ? product.id.split('/').pop() : ''}
              productSKU={handle ?? ''}
              productName={product?.title ?? contentful?.title ?? ''}
              totalReviewsCount={stampedReviewSummary?.count}
            />
          </Box>
        )}
      </main>
    </Box>
  );
}

export default memo(Page);

// --------------------------------------------------------------------------
// Page Data Query: Get the page data (including header props) from Contentful
// --------------------------------------------------------------------------
export const getPageData = async (handle: string) => {
  const data = await queryContentful<{
    pageCollection: {
      items: Array<{
        sys: { id: string };
        headerProps: any;
        title?: string;
        handle: string;
        metaTitle?: string;
        metaDescription?: string;
        seoImage?: { url: string };
        hidePage?: boolean;
        includeProductsReviews?: boolean
        backgroundColor?: { colorArray: string[] };
      }>;
    };
  }>(
    `query PageId($handle: String!) {
      pageCollection(limit: 1, where: {handle: $handle}) {
        items {
          title
          handle
          metaTitle
          metaDescription
          seoImage { url }
          hidePage
          includeProductsReviews
          backgroundColor { colorArray }
          sys { id }
          headerProps: header {
            ${pageHeaderProps}
          }
        }
      }
    }`,
    { handle }
  ).then((res) => res.data.pageCollection.items[0]);

  return data;
};

// --------------------------------------------------------------------------
// Minimal Sections Query: Retrieve only __typename and sys.id for each section
//     (This query uses inline fragments to support all possible section types.)
// --------------------------------------------------------------------------
async function getMinimalSections(pageId: string, skip: number, limit: number) {
  const GET_MINIMAL_SECTIONS = /* GraphQL */ `
    query MinimalSections($id: String!, $skip: Int!, $limit: Int!) {
      page(id: $id) {
        sectionsCollection(limit: $limit, skip: $skip) {
          items {
            __typename
            ... on Entry { sys { id } }
            ... on Quiz { sys { id } }
            ... on ProductVideoReview { sys { id } }
            ... on Faq { sys { id } }
            ... on RoundedImage { sys { id } }
            ... on IconFeatures { sys { id } }
            ... on CollectionProductGrid { sys { id } }
            ... on EmbedHtml { sys { id } }
            ... on TitleSection { sys { id } }
            ... on DropdownLinkList { sys { id } }
            ... on RichPageContent { sys { id } }
            ... on ImageNextToText { sys { id } }
            ... on SplitHeroImage { sys { id } }
            ... on ImageWithTextOverlay { sys { id } }
            ... on PressLogoList { sys { id } }
            ... on ContactForm { sys { id } }
            ... on InstagramSection { sys { id } }
            ... on SubscribeSection { sys { id } }
            ... on TwoButtonTextSection { sys { id } }
            ... on ContentSection { sys { id } }
            ... on SmsSubscribeSection { sys { id } }
          }
        }
      }
    }
  `;

  return queryContentful<{
    page: {
      sectionsCollection: {
        items: Array<{
          __typename: string;
          sys: { id: string };
        }>;
      };
    };
  }>(GET_MINIMAL_SECTIONS, { id: pageId, skip, limit });
}

// --------------------------------------------------------------------------
// Detailed Queries per Section Type: These queries fetch full data for each section type.
//     (Below are examples; add more as needed.)
// --------------------------------------------------------------------------

// --- Quiz Section ---
const GET_QUIZ_SECTION = /* GraphQL */ `
  query getQuiz($id: String!) {
    quiz(id: $id) {
      sys { id }
      __typename
      title
      discountCode
      discountText
      questions: questionsCollection(limit: 6) {
        items {
          questionTitle
          handle
          allowsMultipleAnswers
          allowsOpenText
          weight
          answerOptions: answerOptionsCollection(limit: 10) {
            items {
              answerText
              answerHandle
              products
            }
          }
          helpText
          helpDescription { json }
        }
      }
      headerBlock { sys { id } }
      successHeaderBlock { sys { id } }
    }
  }
`;

async function fetchQuizSection(id: string) {
  const { data, errors } = await queryContentful<any>(GET_QUIZ_SECTION, { id });
  if (errors) {
    console.error('GraphQL Error in fetchQuizSection:', errors);
  }
  return data?.quiz;
}

// --- FAQ Section ---
const GET_FAQ = /* GraphQL */ `
  query getFaq($id: String!) {
    faq(id: $id) {
      sys { id }
      __typename
      title
      questionsCollection {
        items {
          sys { id }
          question
          answer { json }
        }
      }
    }
  }
`;

async function fetchFaqSection(id: string) {
  const { data } = await queryContentful<any>(GET_FAQ, { id });
  return data?.faq;
}

// --- ProductVideoReview Section ---
const GET_PRODUCT_VIDEO_REVIEW = /* GraphQL */ `
  query getProductVideoReview($id: String!) {
    productVideoReview(id: $id) {
      sys {
        id
      }
      __typename
      title
      videoReviewLinks
    }
  }
`;

async function fetchProductVideoReview(id: string) {
  const { data, errors } = await queryContentful<any>(
    GET_PRODUCT_VIDEO_REVIEW,
    { id }
  );
  if (errors) {
    console.error("Error fetching ProductVideoReview:", errors);
  }
  return data?.productVideoReview;
}

// --- RoundedImage Section ---
const GET_ROUNDED_IMAGE = /* GraphQL */ `
  query getRoundedImage($id: String!) {
    roundedImage(id: $id) {
      sys { id }
      __typename
      alt
      image { url height width }
    }
  }
`;

export async function fetchRoundedImage(id: string) {
  const { data } = await queryContentful<any>(GET_ROUNDED_IMAGE, { id });
  return data?.roundedImage;
}

// --- IconFeatures Section ---
const GET_ICON_FEATURES = /* GraphQL */ `
  query getIconFeatures($id: String!) {
    iconFeatures(id: $id) {
      sys { id }
      __typename
      bottomButtonLink
      bottomButtonText
      featuresCollection {
        items {
          sys { id }
          icon { url }
          title
          titleColor { colorArray }
          description { json }
        }
      }
    }
  }
`;

export async function fetchIconFeatures(id: string) {
  const { data } = await queryContentful<any>(GET_ICON_FEATURES, { id });
  return data?.iconFeatures;
}

// --- CollectionProductGrid Section ---
const GET_COLLECTION_PRODUCT_GRID = /* GraphQL */ `
  query getCollectionProductGrid($id: String!) {
    collectionProductGrid(id: $id) {
      sys { id }
      __typename
      isLabReportsSection
      labReportsCollection {
        items {
          productHandle
          imagesCollection {
            items { url height width }
          }
        }
      }
      itemsPerRow
      itemsPerRowMobile
      itemsPerRowTablet
      title
      showTitle
      showDescription
      showReviewsCount
      maxHeight
      imageHeightPercentage
      productHandles
      productsCollection(limit: 20) {
        items {
          productHandle
          imagesCollection(limit: 2) {
            items { url }
          }
        }
      }
    }
  }
`;

export async function fetchCollectionProductGrid(id: string) {
  const { data } = await queryContentful<any>(GET_COLLECTION_PRODUCT_GRID, { id });
  return data?.collectionProductGrid;
}

// --- EmbedHtml Section ---
const GET_EMBED_HTML = /* GraphQL */ `
  query getEmbedHtml($id: String!) {
    embedHtml(id: $id) {
      sys { id }
      __typename
      title
      html
    }
  }
`;

export async function fetchEmbedHtml(id: string) {
  const { data } = await queryContentful<any>(GET_EMBED_HTML, { id });
  return data?.embedHtml;
}

// --- TitleSection ---
const GET_TITLE_SECTION = /* GraphQL */ `
  query getTitleSection($id: String!) {
    titleSection(id: $id) {
      sys { id }
      __typename
      preTitle
      fontWeight
      headline
      headlineTag
      headlineFontSize
      textAlign
      subtitle { json }
    }
  }
`;

export async function fetchTitleSection(id: string) {
  const { data } = await queryContentful<any>(GET_TITLE_SECTION, { id });
  return data?.titleSection;
}

// --- DropdownLinkList Section ---
const GET_DROPDOWN_LINK_LIST = /* GraphQL */ `
  query getDropdownLinkList($id: String!) {
    dropdownLinkList(id: $id) {
      sys { id }
      __typename
      title
      linksCollection(limit: 15) {
        items {
          text
          image { url height width }
          url
        }
      }
    }
  }
`;

export async function fetchDropdownLinkList(id: string) {
  const { data } = await queryContentful<any>(GET_DROPDOWN_LINK_LIST, { id });
  return data?.dropdownLinkList;
}

// --- RichPageContent Section ---
const GET_RICH_PAGE_CONTENT = /* GraphQL */ `
  query getRichPageContent($id: String!) {
    richPageContent(id: $id) {
      sys { id }
      __typename
      title
      content { json }
      padding
      maxWidth
      textAlign
    }
  }
`;

export async function fetchRichPageContent(id: string) {
  const { data } = await queryContentful<any>(GET_RICH_PAGE_CONTENT, { id });
  return data?.richPageContent;
}

// --- ImageNextToText Section ---
const GET_IMAGE_NEXT_TO_TEXT = /* GraphQL */ `
  query getImageNextToText($id: String!) {
    imageNextToText(id: $id) {
      sys { id }
      __typename
      image { url height width }
      bulletPointsCollection {
        items { text, icon { url } }
      }
      iconGroupCollection {
        items {
          title
          subTitle
          description { json }
          image { url }
        }
      }
      title
      titleTag
      preTitle
      underlineButton
      buttonUrl
      buttonText
      flipped
      description { json }
      backgroundColor { colorArray }
      textColor { colorArray }
    }
  }
`;

export async function fetchImageNextToText(id: string) {
  const { data } = await queryContentful<any>(GET_IMAGE_NEXT_TO_TEXT, { id });
  return data?.imageNextToText;
}

// --- SplitHeroImage Section ---
const GET_SPLIT_HERO_IMAGE = /* GraphQL */ `
  query getSplitHeroImage($id: String!) {
    splitHeroImage(id: $id) {
      sys { id }
      __typename
      title
      titleTag
      herosubtitle: subtitle
      image { url }
      imageIsOnRight
      maxHeight
      minHeight
      contentColor { colorArray }
      contentBackgroundColor { colorArray }
    }
  }
`;

export async function fetchSplitHeroImage(id: string) {
  const { data } = await queryContentful<any>(GET_SPLIT_HERO_IMAGE, { id });
  return data?.splitHeroImage;
}

// --- ImageWithTextOverlay Section ---
const GET_IMAGE_WITH_TEXT_OVERLAY = /* GraphQL */ `
  query getImageWithTextOverlay($id: String!) {
    imageWithTextOverlay(id: $id) {
      sys { id }
      __typename
      secondButtonText: secondButtonTitle
      secondButtonUrl
      thirdButtonText
      thirdButtonUrl
      forthButtonText
      forthButtonUrl
      image { url height width }
      preTitle
      titleTag
      description { json }
      title
      titleSize
      titleLineHeight
      contentColor { colorArray }
      verticalPosition
      underlineButton
      contentPadding
      minHeight
      buttonText
      buttonUrl
      horizontalPosition
      textAlign
      maxHeight
      imageFocusPosition
      contentMobilePosition
      contentMaxWidth
    }
  }
`;

export async function fetchImageWithTextOverlay(id: string) {
  const { data } = await queryContentful<any>(GET_IMAGE_WITH_TEXT_OVERLAY, { id });
  return data?.imageWithTextOverlay;
}

// --- PressLogoList Section ---
const GET_PRESS_LOGO_LIST = /* GraphQL */ `
  query getPressLogoList($id: String!) {
    pressLogoList(id: $id) {
      sys { id }
      __typename
      asSeenText
      backgroundColor { colorArray }
      linksCollection {
        items {
          quote
          publicationName
          image { url height width }
          url
        }
      }
    }
  }
`;

export async function fetchPressLogoList(id: string) {
  const { data } = await queryContentful<any>(GET_PRESS_LOGO_LIST, { id });
  return data?.pressLogoList;
}

// --- ContactForm Section ---
const GET_CONTACT_FORM = /* GraphQL */ `
  query getContactForm($id: String!) {
    contactForm(id: $id) {
      sys { id }
      __typename
      title
      successTitle
      successMessage
    }
  }
`;

export async function fetchContactForm(id: string) {
  const { data } = await queryContentful<any>(GET_CONTACT_FORM, { id });
  return data?.contactForm;
}

// --- InstagramSection ---
const GET_INSTAGRAM_SECTION = /* GraphQL */ `
  query getInstagramSection($id: String!) {
    instagramSection(id: $id) {
      sys { id }
      __typename
      title
      subtitle
      handle
      postsCollection {
        items {
          image { url }
          alt
          url
        }
      }
    }
  }
`;

export async function fetchInstagramSection(id: string) {
  const { data } = await queryContentful<any>(GET_INSTAGRAM_SECTION, { id });
  return data?.instagramSection;
}

// --- SubscribeSection ---
const GET_SUBSCRIBE_SECTION = /* GraphQL */ `
  query getSubscribeSection($id: String!) {
    subscribeSection(id: $id) {
      sys { id }
      __typename
      subscribeText
      buttonText
      backgroundColor { colorArray }
      textColor { colorArray }
    }
  }
`;

export async function fetchSubscribeSection(id: string) {
  const { data } = await queryContentful<any>(GET_SUBSCRIBE_SECTION, { id });
  return data?.subscribeSection;
}

// --- TwoButtonTextSection ---
const GET_TWO_BUTTON_TEXT_SECTION = /* GraphQL */ `
  query getTwoButtonTextSection($id: String!) {
    twoButtonTextSection(id: $id) {
      sys { id }
      __typename
      title
      description { json }
      leftText
      firstButtonImage { url }
      firstButtonText
      firstButtonUrl
      secondButtonImage { url }
      secondButtonText
      secondButtonUrl
    }
  }
`;

export async function fetchTwoButtonTextSection(id: string) {
  const { data } = await queryContentful<any>(GET_TWO_BUTTON_TEXT_SECTION, { id });
  return data?.twoButtonTextSection;
}

// --- ContentSection ---
const GET_CONTENT_SECTION = /* GraphQL */ `
  query getContentSection($id: String!) {
    contentSection(id: $id) {
      sys { id }
      __typename
      title
      description { json }
      horizontal
      itemsCollection {
        items {
          image { url }
          title
          subTitle
          description { json }
        }
      }
      showContentBorder
      backgroundColor { colorArray }
      textColor { colorArray }
      borderColor { colorArray }
    }
  }
`;

export async function fetchContentSection(id: string) {
  const { data } = await queryContentful<any>(GET_CONTENT_SECTION, { id });
  return data?.contentSection;
}

// --- SmsSubscribeSection ---
const GET_SMS_SUBSCRIBE_SECTION = /* GraphQL */ `
  query getSmsSubscribeSection($id: String!) {
    smsSubscribeSection(id: $id) {
      sys { id }
      __typename
      title
      titleColor { colorArray }
      background { url }
      image {
        url
        height
        width
      }
    }
  }
`;

export async function fetchSmsSubscribeSection(id: string) {
  const { data } = await queryContentful<any>(GET_SMS_SUBSCRIBE_SECTION, { id });
  return data?.smsSubscribeSection;
}

// --------------------------------------------------------------------------
// Section Fetcher: Switch on __typename to call the matching detailed fetch function
// --------------------------------------------------------------------------
async function fetchSectionByType(typeName: string, sectionId: string) {
  // console.log(`Fetching section: ${typeName}, ID: ${sectionId}`);

  switch (typeName) {
    case 'Quiz':
      return await fetchQuizSection(sectionId);
    case 'Faq':
      return await fetchFaqSection(sectionId);
    case 'ProductVideoReview':
      return await fetchProductVideoReview(sectionId);
    case 'RoundedImage':
      return await fetchRoundedImage(sectionId);
    case 'IconFeatures':
      return await fetchIconFeatures(sectionId);
    case 'CollectionProductGrid':
      return await fetchCollectionProductGrid(sectionId);
    case 'EmbedHtml':
      return await fetchEmbedHtml(sectionId);
    case 'TitleSection':
      return await fetchTitleSection(sectionId);
    case 'DropdownLinkList':
      return await fetchDropdownLinkList(sectionId);
    case 'RichPageContent':
      return await fetchRichPageContent(sectionId);
    case 'ImageNextToText':
      return await fetchImageNextToText(sectionId);
    case 'SplitHeroImage':
      return await fetchSplitHeroImage(sectionId);
    case 'ImageWithTextOverlay':
      return await fetchImageWithTextOverlay(sectionId);
    case 'PressLogoList':
      return await fetchPressLogoList(sectionId);
    case 'ContactForm':
      return await fetchContactForm(sectionId);
    case 'InstagramSection':
      return await fetchInstagramSection(sectionId);
    case 'SubscribeSection':
      return await fetchSubscribeSection(sectionId);
    case 'TwoButtonTextSection':
      return await fetchTwoButtonTextSection(sectionId);
    case 'ContentSection':
      return await fetchContentSection(sectionId);
    case 'SmsSubscribeSection':
      return await fetchSmsSubscribeSection(sectionId);
    default:
      console.warn(`No fetcher for typeName="${typeName}". Returning null.`);
      return null;
  }
}

// --------------------------------------------------------------------------
// Combine Minimal and Full Queries: Paginate through sections and fetch details concurrently
// --------------------------------------------------------------------------
async function getPageSections(pageId: string) {
  const allSections: any[] = [];
  const limit = 5;
  let skip = 0;

  while (true) {
    // A) Get minimal IDs
    const { data, errors } = await getMinimalSections(pageId, skip, limit);
    if (errors) {
      console.error("Error in getMinimalSections:", errors);
    }

    // Get items array (may contain nulls)
    const items = data?.page?.sectionsCollection?.items ?? [];
    if (!items.length) break;

    // B) Filter out any null items
    const nonNullItems = items.filter(Boolean); 
    // or `items.filter(item => item && item.sys?.id)`

    // C) Fetch full data for each item concurrently
    const batchPromises = nonNullItems.map(async (item) => {
      // item contains { __typename, sys: { id } }
      const sectionData = await fetchSectionByType(item.__typename, item.sys.id);

      // If query returned data, unify it with minimal info
      if (sectionData) {
        return {
          __typename: item.__typename,
          sys: { id: item.sys.id },
          ...sectionData,
        };
      }
      return {
        __typename: item.__typename,
        sys: { id: item.sys.id },
      };
    });

    const batchResults = await Promise.all(batchPromises);
    allSections.push(...batchResults);

    skip += limit;
  }
  return allSections;
}

const getContentfulProductList = async (handle: string) => {
  const contentfulQuery = `#graphql
  {
    productCollection(limit: 75) {
      items {
        handle
        shortDescription
        productImagesCollection(limit: 1) {
          items {
            image {
              url
            }
          }
        }
      }
    }
  }
  `

  type ContentfulQuery = {
    productCollection: {
      items: {
        handle: string
        shortDescription: string
        productImagesCollection: {
          items: {
            image: {
              url: string
              contentType: string
            }
          }[]
        }
      }[]
    }
  }

  const contentfulProductList = await queryContentful<ContentfulQuery>(
    contentfulQuery,
    {}
  )
  return contentfulProductList.data.productCollection.items
}

// --------------------------------------------------------------------------
// getStaticProps: Use the above functions to fetch page data and section details
// --------------------------------------------------------------------------
async function fetchAllStampedReviews(
  productId: string,
  options: { limit: number; sortBy?: string; hasPhotos?: boolean }
) {
  let page = 1;
  const reviews: any[] = [];
  while (true) {
    const res = await getStampedReviewsByProductId__client(productId, {
      ...options,
      page,
    });
    if (!res?.data || res.data.length === 0) break;
    reviews.push(...res.data);
    if (res.data.length < options.limit) break;
    page++;
  }
  return reviews;
}


export const getStaticProps = (async ({ params }) => {
  const handle = params?.handle as string;
  const data = await getPageData(handle);

  if (!data || data.hidePage) {
    return { notFound: true };
  }

  const contentfulData = { ...data, includeProductsReviews: data.includeProductsReviews ?? false };

  if (handle === 'testimonials') {
    const allActiveProductsRaw = await getAllActiveShopifyProductPages();
    const validProducts = allActiveProductsRaw.filter((p) => p && p.id);
    const productIds = validProducts
      .map((p) => p.id.split('/').pop())
      .filter(Boolean) as string[];

    const productList = await getContentfulProductList(handle);

    let combinedReviews: any[] = [];
    for (const id of productIds) {
      const reviewsForProduct = await fetchAllStampedReviews(id, {
        limit: 20,
        sortBy: 'highest-rating',
      });
      combinedReviews.push(...reviewsForProduct);
    }
    
    combinedReviews.sort((a, b) => Number(b.reviewRating) - Number(a.reviewRating));

    const stampedReviewSummary =
      combinedReviews.length > 0
        ? {
            rating:
              combinedReviews.reduce(
                (sum, r) => sum + Number(r.reviewRating || 0),
                0
              ) / combinedReviews.length,
            count: combinedReviews.length,
          }
        : { rating: 4.5, count: 10 };

    const reviewsWithMedia = { data: combinedReviews.filter((r) => r.photos && r.photos.length > 0) };

    const allStampedReview = { data: combinedReviews };

    const allStampedQuestion = await getStampedAllQuestions().then(
      (res) => res?.results ?? []
    );

    const sections = await getPageSections(data.sys.id);

    return {
      props: await withPageProps({
        headerProps: data.headerProps,
        contentful: { ...contentfulData, sections },
        stampedReviewSummary,
        allStampedReview,
        reviewsWithMedia,
        allStampedQuestion,
        productList,
      }),
      revalidate: 60 * 60,
    };
  }

  const sections = await getPageSections(data.sys.id);
  return {
    props: await withPageProps({
      headerProps: data.headerProps,
      contentful: { ...contentfulData, sections },
      stampedReviewSummary: null,
      allStampedReview: null,
      reviewsWithMedia: null,
      allStampedQuestion: null,
      product: null,
      handle: null,
    }),
    revalidate: 60 * 60, // Revalidate every hour
  };
}) satisfies GetStaticProps;

// --------------------------------------------------------------------------
// getAllContentfulPages & getStaticPaths: Unchanged – used to generate static paths
// --------------------------------------------------------------------------
export const getAllContentfulPages = (cache = false) =>
  queryContentful<{
    pageCollection: {
      items: {
        handle: string;
        hidePage: boolean;
        includeProductsReviews: boolean;
        sys: { publishedAt: string };
      }[];
    };
  }>(
    `query AllPages {
      pageCollection {
        items {
          handle
          hidePage
          includeProductsReviews
          sys { publishedAt }
        }
      }
    }`,
    {}
  );

export const getStaticPaths = (async () => {
  const allPages = await getAllContentfulPages();
  return {
    paths: allPages.data.pageCollection.items
      .filter((page) => !page.hidePage)
      .map((page) => ({ params: { handle: page.handle } })),
    fallback: true,
  };
}) satisfies GetStaticPaths;
